import Head from 'next/head';
import Loader from '~/components/common/Loader';
import { StoryBloks } from '~/components/structure';
import { StoryLayout } from '~/layouts';
import { useCart } from '~/utils/cart';
import { storyBlokStaticProps, storyblokPage } from '~/utils/storyblok';
import { useContext, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useNavigation } from '~/utils/navigation';
import { AuthenticationContext } from '~/components/context/Authentication';
import { useLoginAndRegisterModal } from '~/utils/useLoginAndRegisterModal';

const SEO_TITLE = 'Peça Aí - O maior estoque de peças para a sua oficina';
const SEO_IMAGE = 'https://assets.peca.ai/parts/pecai-logo-meta.jpeg';
const SEO_DESC =
  'Com o Peça Aí, você acha a peça certa para cada carro e compra num só lugar. ' +
  'É o maior estoque digital de peças do país! ' +
  'ENTREGA EM ATÉ 2 HORAS! PREÇOS 20% MENORES! + DE 100.000 PEÇAS';

export const Home = storyblokPage(({ story }) => {
  const { loading } = useCart();
  const { isLoggedIn } = useContext(AuthenticationContext);
  const { goTo, getBackPage } = useNavigation();
  const router = useRouter();
  const { handleGoToLoginModal } = useLoginAndRegisterModal();

  useEffect(() => {
    if(router.isReady){
      const backPage = getBackPage();
      const { callback } = router.query;
      if(process.browser && isLoggedIn && callback){
        goTo(callback);
      }else if(!isLoggedIn && callback && backPage !== '/logout'){
        handleGoToLoginModal();
      }
    }
  }, [router.isReady, isLoggedIn])


  return (
    <StoryLayout unsecure={true} story={story}>
      <Head>
        <title>Peça aí - O maior estoque de peças para a sua oficina</title>
        <meta name="title" content={SEO_TITLE} />
        <meta name="description" content={SEO_DESC} />
        <meta property="og:title" content={SEO_TITLE} />
        <meta property="og:description" content={SEO_DESC} />
        <meta property="og:image" content={SEO_IMAGE} />
      </Head>
      {/* Render all components for this story */}
      <StoryBloks bloks={story.content.body} />

      {loading && <Loader full />}
    </StoryLayout>
  );
});

export const getStaticProps = storyBlokStaticProps('home');

export default Home;
